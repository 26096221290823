// Variables
@import 'variables';
[v-cloak] {
    display: none;
}

@import url("https://p.typekit.net/p.css?s=1&k=xnx0vcg&ht=tk&f=26058.26059.26062.26063.26000.26004.26026&a=80213250&app=typekit&e=css");

@font-face {
    font-family:"acumin-pro";
    src:url("https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"acumin-pro";
    src:url("https://use.typekit.net/af/51b548/00000000000000003b9acaf5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/51b548/00000000000000003b9acaf5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/51b548/00000000000000003b9acaf5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"acumin-pro";
    src:url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"acumin-pro";
    src:url("https://use.typekit.net/af/027dd4/00000000000000003b9acafa/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/027dd4/00000000000000003b9acafa/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/027dd4/00000000000000003b9acafa/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
    font-family:"acumin-pro-condensed";
    src:url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"acumin-pro-condensed";
    src:url("https://use.typekit.net/af/c64ee1/00000000000000003b9acb06/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/c64ee1/00000000000000003b9acb06/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/c64ee1/00000000000000003b9acb06/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"acumin-pro-extra-condensed";
    src:url("https://use.typekit.net/af/8d1fa8/00000000000000003b9acb1e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/8d1fa8/00000000000000003b9acb1e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/8d1fa8/00000000000000003b9acb1e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

.tk-acumin-pro { 
    font-family: "acumin-pro",sans-serif; 
}

.tk-acumin-pro-condensed { 
    font-family: "acumin-pro-condensed",sans-serif; 
}

.tk-acumin-pro-extra-condensed { 
    font-family: "acumin-pro-extra-condensed",sans-serif; 
}

a {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

body {
    font-family: "acumin-pro",sans-serif; 
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    background: url('../images/bitebg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
}

.modal-open {
    filter: none;
}

#app {
    min-height: 100vh;
}
.navbar-collapse {
    background: #FFFFFF;
    min-height: 41px;
}
.container-fluid {
    width: 100%;
    .nav-item .nav-link {
        color: #17788E !important;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.025em;
        padding: 0;
        margin: 10px 15px;
        &.selected {
            position: relative;
            font-weight: 600;
            &:before {
                content: "";
                width: 100%;
                height: 2px;
                background: #17788E;
                position: absolute;
                left: 0;
                bottom: -2px;
            }
        }
    }
}
.logo-bg {
    height: 41px;
    width:85px;
    background-image: url("../images/logo.png");
}
.language {
    font-family: 'ElegantIcons';
    background: url('../images/language.jpg');
    width: 24px;
    height: 24px;
    right: 5px;
    position: relative;
}

.middle-align {
    margin: 10% auto;
    border: 1px solid;
}
.item-space {
    align-items: center;
    letter-spacing: 0.025em;
}
.color-red {
    color: $red !important
}
.load-more {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    color: $blue_15
}
.custom-btn-primary,
.swal2-cancel {
    font-family: "acumin-pro-extra-condensed",sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    background-color: #157FA9;
    border-radius: 5px;
    color: $white;
    letter-spacing: 0.025em;
}

.custom-btn-primary:hover,
.custom-btn-primary:active,
.custom-btn-primary:focus,
.swal2-cancel:hover,
.swal2-cancel:active,
.swal2-cancel:focus {
    color: $white;
    background-color: #1890bf;
}

.custom-btn-danger,
.swal2-confirm {
    font-family: "acumin-pro-extra-condensed",sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    border-radius: 5px;
    color: $white;
    letter-spacing: 0.025em;
    background: #ff4747;
}

.custom-btn-danger:hover,
.custom-btn-danger:focus,
.custom-btn-danger:active,
.swal2-confirm:hover,
.swal2-confirm:focus,
.swal2-confirm:active {
    color: $white;
}

.login-card-body,
.reset-password-card-body {
    padding: 1rem 2rem;
}

.login-content,
.reset-password-container,
.change-password-container,
.profile-container {
    .login-header,
    .reset-password-header,
    .change-password-header,
    .profile-header {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #565656;
    }
    .login-button {
        font-family: "acumin-pro-extra-condensed",sans-serif;
        font-style: normal;
        background-color: #157FA9;
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0.025em;
        border-radius: 5px;
    }

    .login-button:hover {
        background-color: #1890bf;
        color: #ffffff; 
    }
    
    .link-text {
        text-decoration: none;
        font-style: normal;
        line-height: 22px;
        align-items: center;
        letter-spacing: 0.025em;
        color: #157FA9 !important;
    }
    .google-icon {
        position: relative;
        background: url('../images/google_icon.png');
        width: 26px;
        height: 26px;
        right: 5px;
    }
    .social-bg {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        background: #F9F9F9 !important;
        border: 1px solid #C0C0C0;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        color: #282828;
        letter-spacing: 0.025em;
    }
    .micro-icon {
        float: left;
        background: url('../images/mslogo.png');
        width: 26px;
        height: 26px;
        margin-right: 10px;
    }
    .text-or {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.025em;
        color: #C0C0C0;
        padding: 0px 5px;
    }
    .remember-me {
        line-height: 22px;
    }
    .hr-divider {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        color: #C0C0C0;
    }
    .hr-divider:before,
    .hr-divider:after {
        content: "";
        flex-grow: 1;
        background: #C0C0C0;
        height: 1px;
        font-size: 0px;
        line-height: 0px;
        margin: 0px;
    }

    .col-form-email,
    .col-form-password,
    .col-form-text,
    .remember-me,
    .link-text {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.025em;
        color: #565656;
    }

    .invalid-feedback {
        font-family: "acumin-pro-condensed",sans-serif;
        font-weight: 300;
        font-size: 18px;
    }

    .form-control {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.025em;
        background-color: #fdfdfd;
    }

    .alert-success {
        font-family: "acumin-pro-condensed",sans-serif;
    }
}
.exercises-block, .users-block {
    .welcome-text {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 36px;
        color: $black_28;
        .username {
            color: #157fa9;
        }
    }
    .content-caption {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: $black_28;
        letter-spacing: 0.025em;
    }

    #sorting {
        width: auto;
        background-color: #fdfdfd;
        color: #157fa9;
    }

    .sorting-text {
        color: $blue_15;
    }
    .exercise-content {
        background: $silver;
        height: 12.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content-title {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $black_28;
        letter-spacing: 0.025em;
    }
    .content-footer {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #565656;
        letter-spacing: 0.025em;
    }
    .sort-text {
        padding: 0.4rem 0;
    }

    .search-exercises {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        background-color: #fdfdfd;
    }

    .search-exercises::placeholder {
        color: #919191;
    }
      
    .search-exercises:-ms-input-placeholder {
        color: #919191;
    }
      
    .search-exercises::-ms-input-placeholder {
        color: #919191;
    }
}
.exercise-block, .users-block {
    .top-text {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        color: $blue_15;
        letter-spacing: 0.025em;
    }
    .hse-text {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        line-height: 26px;
        letter-spacing: 0.025em;
        color: #282828;
    }
    .edit-deatils {
        font-family: "acumin-pro-condensed",sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.025em;
        color: $blue_15;
        a {
            color: $blue_15;
        }
    }
    .text-content {
        font-family: "acumin-pro-condensed",sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.025em;
        color: #565656;
    }
    .text-email {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $blue_15;
    }

    .exercise-table th {
        font-family: "acumin-pro-extra-condensed",sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.025em;
        color: $black_28;
    }

    .exercise-table td, 
    .exercise-table .public-url {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.025em;
        color: #282828;
    }

    .exercise-table td {
        vertical-align: middle;
    }

    .exercise-table .public-url {
        background: #FFFFFF;
        border: 1px solid #C0C0C0;
        border-radius: 3px;
    }

    .exercise-table tbody tr {
        background: #f9f9f9;
        border-bottom: 3px solid $white;
    }
    .table-icon {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.025em;
        color: $blue_15;
    }

    .delete-icon-color {
        color: #ff4747;
    }

    .exercise-box {
        background: $silver;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 12.25rem;
    }

    .links-title {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.025em;
        color: #282828;
    }

    .search-links {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        background-color: #fdfdfd;
    }

    .search-links::placeholder {
        color: #919191;
    }
      
    .search-links:-ms-input-placeholder {
        color: #919191;
    }
    
    .search-links::-ms-input-placeholder  {
        color: #919191;
    }

    .return-library-arrow {
        vertical-align: bottom;
    }

    .copy-private-link-icon {
        display: inline-block;
    }
}
.error-block {
    margin: 10% auto;
    .error-header {
        font-weight: 400;
        font-size: 2.813rem;
        line-height: 3.375rem;
        letter-spacing: 0.025em;
        color: $black_28
    }
    .wrong-text {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.188rem;
        letter-spacing: 0.025em;
        color: $black_28;;
    }
    .wrong-msg {
        border: 1px solid $red_ff;
        background: $red_f7;
        box-sizing: border-box;
        border-radius: 0.313rem;
        color: $red_ff;
        font-style: italic;
    }
    .copy-error-msg {
        font-weight: 300;
        color: $blue_15;
    }
}
.copy-icon {
    display: block;
    padding: 9px 5px 0px;
    cursor: pointer;
}
.tip{
    background-color: #6cb2eb;
    padding: 0 14px;
    line-height: 27px;
    position: absolute;
    border-radius: 4px;
    z-index: 100;
    color: #fff;
    font-size: 12px;
    animation-name: tip;
    animation-duration: .6s;
    animation-fill-mode: both
}
.tip:before{
    content: "";
    background-color: #6cb2eb;
    height: 10px;
    width: 10px;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    top: -4px;
    left: 9px
}
#copied_tip{
    animation-name: come_and_leave;
    animation-duration: 1s;
    animation-fill-mode: both;
}
.cursor-pointer {
    cursor: pointer;
}
.presigned-loader {
    width: 40px;
    height: 40px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: presigned-rotation 1s linear infinite;
    margin-left: 10px;
}

@keyframes presigned-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.no-found {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
}

.swal2-popup {
    font-family: "acumin-pro", sans-serif;
    padding: 0px !important;

    .swal2-content {
        padding: 15px 20px;
        font-size: 0.9375rem !important;
    }

    .swal2-header {
        background: linear-gradient(180deg, #8E8E8E 0%, #626262 100%);
        border-radius: 5px 5px 0px 0px;
        align-items: start;
    }

    .swal2-title,
    .swal2-title h4 {
        font-family: "acumin-pro-condensed",sans-serif;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 20px !important;
        line-height: 24px !important;
        letter-spacing: 0.025em !important;
        margin-bottom: 0;
        padding: 6px 10px;
        color: #fdfdfd;
    }

    .swal2-input {
        background: #F9F9F9;
        border: 1px solid #C0C0C0;
        box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        height: 39px;
        color: #282828;
    }

    .swal2-input:focus {
        background: #F9F9F9;
        border: 1px solid #C0C0C0;
        box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.25);
    }

    .swal2-actions {
        border-top: 1px solid #dee2e6;
        margin-top: 0px;
        padding: 15px 20px;
        align-items: end;
        justify-content: end;
    }
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, .4);
}

.modal-custom-content {
    font-family: "acumin-pro-condensed",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.025em;
    color: #282828;
    background-color: #F9F9F9;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.modal-custom-content .form-control {
    font-family: "acumin-pro-condensed",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #282828;
    background: #FDFDFD;
    border: 1px solid #C0C0C0;
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.modal-custom-header {
    font-family: "acumin-pro-condensed",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.025em;
    color: #fdfdfd;
    background: linear-gradient(180deg, #8E8E8E 0%, #626262 100%);
    border-radius: 5px 5px 0px 0px;
    padding: .60rem 1rem;
}

.btn-custom-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #fdfdfd;
    background: transparent;
    border: 0;
    border-radius: 0.25rem;
    opacity: 1;
}

.required-star-mark {
    color: #ff4747;
}

.pagination {
    font-family: "acumin-pro-condensed",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.025em;
}

@media (max-width: 767.98px) {
    .login-content,
    .reset-password-container,
    .change-password-container,
    .profile-container {
        .col-form-email,
        .col-form-password,
        .col-form-text {
            text-align: left !important;
        }
    }
}